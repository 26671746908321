import { mapState, mapActions } from 'vuex'
import SCard from '../../../components/ui/s-card'
import SLink from '../../../components/ui/s-link'

export default {
  name: 'MyIssues',
  components: {
    SCard,
    SLink,
    IssueCard: () => import('../../../components/IssueCard'),
  },

  data() {
    return {
      fullyLoaded: true,
    }
  },

  computed: mapState(['issues', 'loading', 'error']),

  async created() {
    await this.getIssues()
  },

  methods: mapActions(['getIssues', 'paginateIssues']),
}
